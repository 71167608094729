//Libs
import { css } from "emotion";
import { bindActionCreators } from "redux";
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import Switch from "react-switch";

//Styles
import colors from "../../styles/colors";

//Components
import TextArea from "../ui/TextArea";
import Button_DEPRECATED from "../ui/Button_DEPRECATED";

// Utilities and config
import req from "../../utilities/request-utility";

//Actions
import { addToast } from "../../actions/uiActions";

const GlobalDefaultFee = (props) => {
  const [globalFee, setGlobalFee] = useState("");
  const [loading, setLoading] = useState(false);
  const [extraFee, setExtraFee] = useState(false);
  
  useEffect(() => {
    req()
    .get(`admin/bookings/global-message`)
    .then((res) => {
      const defaultFee = {
        standard_fee : res.data.standard_fee,
        urgent_booking_fee : res.data.urgent_booking_fee,
        emergency_booking_fee : res.data.emergency_booking_fee
      }
      setGlobalFee(defaultFee)
      setExtraFee(res.data.extra_fee)
    });
  }, [])
  const updateGlobalFee = async() => {
    setLoading(true)
    await req()
    .put(`admin/bookings/update-global-message`, {
      standard_fee : globalFee.standard_fee,
      urgent_booking_fee : globalFee.urgent_booking_fee,
      emergency_booking_fee : globalFee.emergency_booking_fee
  })
    .then(() => {
      setLoading(false)

      props.addToast({
        message: "opdatering af den globale fee lykkedes",
        type: "success",
        autoDismiss: true,
      });
    })
    .catch(() => {
      setLoading(false)

      props.addToast({
        message: "opdatering mislykkes",
        type: "error",
        autoDismiss: true,
      });
    });

  }
    const handleExtraFeeStatusChange = async () => {
      const updatedExtraFee = !extraFee;
        setExtraFee(updatedExtraFee);
        setLoading(true)
        await req()
          .put(`admin/bookings/update-global-message`, {
            extra_fee : updatedExtraFee,
        })
        .then(() => {
          setLoading(false)
    
          props.addToast({
            message: "opdatering af ekstra gebyr lykkedes",
            type: "success",
            autoDismiss: true,
          });
        })
        .catch(() => {
          setLoading(false)
    
          props.addToast({
            message: "opdatering mislykkes",
            type: "error",
            autoDismiss: true,
          });
        });
        };
  
  return (
    <div className={componentStyle(colors)}>
      <div className="bottom-container">
      <div className="section-four">
                <div className="price-container">
                  <label>Service Tillæg</label>
                  <div style={{ position: "relative" }}>
                    <input
                      value={globalFee.standard_fee}
                      name="standard_fee"
                      className="default-input"
                      onChange={(e) => {
                        if (!(/^[0-9,]+$/.test(e.target.value))) return;
                        setGlobalFee({
                          ...globalFee,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <div className="png-div-left">
                      <p className="text-sm">DKK</p>
                    </div>
                  </div>
                </div>
                <div className="price-container">
                  <label>{`Hastebooking (<48 timer)`}</label>
                  <div style={{ position: "relative" }}>
                    <input
                      value={globalFee.urgent_booking_fee}
                      name="urgent_booking_fee"
                      className="default-input"
                      onChange={(e) => {
                        if (!(/^[0-9,]+$/.test(e.target.value))) return;
                        setGlobalFee({
                          ...globalFee,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <div className="png-div-left">
                      <p className="text-sm">DKK</p>
                    </div>
                  </div>
                </div>
                <div className="price-container">
                  <label>{`Akutbooking (<24 timer)`}</label>
                  <div style={{ position: "relative" }}>
                    <input
                      value={globalFee.emergency_booking_fee}
                      name="emergency_booking_fee"
                      className="default-input"
                      onChange={(e) => {
                        if (!(/^[0-9,]+$/.test(e.target.value))) return;
                        setGlobalFee({
                          ...globalFee,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <div className="png-div-left">
                      <p className="text-sm">DKK</p>
                    </div>
                  </div>
                </div>
              </div>

        <Button_DEPRECATED
          className="update-message"
          onClick={() => {
            updateGlobalFee();
          }}
          disabled={loading}
          title={loading ? `opdatering...`: `opdatere global fee`}
        />
      </div>
      <div className="worker-option">
        <p>{`>9t+50%`}</p>
        <Switch
          className="worker-switch"
          onChange={() => {
            handleExtraFeeStatusChange();
          }}
          checked={extraFee}
          uncheckedIcon={false}
          checkedIcon={false}
        />
      </div>
    </div>
  );
};

const componentStyle = (colors) => css`
    .worker-option{
      margin-top: 10px;
    }

  .bottom-container {
    max-width: 500px;
    margin-right: auto;

    .section-four {
      display: flex;
      margin: 1.25rem 0;
      flex-direction: column;
      .price-container {
        width: 50%;
        margin-right: 0.5rem;
      }
      .png-div-left,
      .png-div-right {
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: ${colors.black};
      }
    
    }
    .default-input {
      display: block;
      font-size: 0.75rem;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      width: 100%;
      border: 1px solid ${colors.darkGrey};
      padding: 0.5rem;
      border-radius: 0.25rem;
      line-height: 1.25;
      outline: none;
    }
    


    .update-message {
      margin-top: 20px;
      padding: 0.55rem;
    }
  }
`;

const mapDispatchToProps = (dispatch) => ({
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(GlobalDefaultFee);
