// Libs
import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { connect, useSelector } from "react-redux";
import _ from "lodash";
import { css } from "emotion";
import Switch from "react-switch";

// Utilities
import req from "../../../utilities/request-utility";
import validateEmail from "../../../utilities/validate-email";

// Actions
import { updateUser } from "../../../actions/authActions";
import { addToast } from "../../../actions/uiActions";

// Components
import ImageUpload from "../../ui/ImageUpload";
import Spinner from "../../ui/Spinner";

// Validation
import { changePasswordValidation } from "../../../validation/changePasswordValidator";
import CheckoutValidation from "../../../validation/checkoutValidation";

// Styles
import colors from "../../../styles/colors";

const Account = (props) => {
  // Validation form
  const validator = new CheckoutValidation(changePasswordValidation);

  // Validation submitted
  const [submitted, setSubmitted] = useState(false);

  const [loading, setLoading] = useState(false);
  const [button, setButton] = useState(false);
  const [passwordButton, setPasswordButton] = useState(false);

  const [iconSpinner, setIconSpinner] = useState(false);
  const [profileSpinner, setProfileSpinner] = useState(false);

  const { user, takenOverCorporation } = useSelector((state) => state.auth);

  const [updatedUser, setUpdatedUser] = useState({ ...user });

  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
    validation: validator.valid(),
  });

  const [billingEmailNotValid, setBillingEmailNotValid] = useState(true);

  const [showChangePassword, setShowChangePassword] = useState(false);

  const [validation, setValidation] = useState(password.validation);
  const baseURL_image = process.env.REACT_APP_CLOUDINARY_BASE_URL_IMAGE;

  useEffect(() => {
    if (submitted) {
      setValidation(validator.validate(password));
    } else {
      setValidation(password.validation);
    }
    // eslint-disable-next-line
  }, [password]);
  useEffect(() => {
    if (
      !_.isEqual(
        _.omit(updatedUser, ["icon_image", "profile_image", "baseURL_image"]),
        _.omit(user, ["icon_image", "profile_image", "baseURL_image"])
      )
    ) {
      setButton(true);
    } else {
      setButton(false);
    }
    // eslint-disable-next-line
  }, [updatedUser]);

  useEffect(() => {
    const { standard_fee, urgent_booking_fee, emergency_booking_fee } = updatedUser;
    if(standard_fee == 0 && urgent_booking_fee == 0 && emergency_booking_fee == 0){
      req()
      .get(`admin/bookings/global-message`)
      .then((res) => {
        const { standard_fee, urgent_booking_fee, emergency_booking_fee } = res.data;
        setUpdatedUser({ ...updatedUser, standard_fee, urgent_booking_fee, emergency_booking_fee })
      });
    }
  }, [])

  useEffect(() => {
    if (
      password.password !== undefined &&
      password.password.length > 0 &&
      password.confirmPassword !== undefined &&
      password.confirmPassword.length > 0
    ) {
      setPasswordButton(true);
    } else {
      setPasswordButton(false);
    }
    // eslint-disable-next-line
  }, [password]);

  const update = () => {
    if (!billingEmailNotValid) {
      return props.addToast({
        message: "Nogle af felterne er ikke udfyldt korrekt",
        type: "warning",
        autoDismiss: true,
      });
    }

    setLoading(true);
    req()
      .put("corporations/", updatedUser)
      .then(({ data: user }) => {
        props.updateUser(user);
        setLoading(false);
        setButton(false);
        props.addToast({
          message: "Oplysingerne er gemt!",
          type: "success",
          autoDismiss: true,
        });
      })
      .catch((error) => {
        setLoading(false);

        if (error.response.status === 409) {
          props.addToast({
            message: "Email/Brugernavn findes allerede",
            type: "warning",
            autoDismiss: true,
          });
        } else {
          props.addToast({
            message:
              "Kunne ikke gemme ændringerne. Hvis fejlen fortsætter, venligst kontakt JobButler",
            type: "warning",
            autoDismiss: true,
          });
        }
      });
  };

  const uploadLogo = (file) => {
    const imageInfo = {
      baseURL_image,
      icon_image: file?.imageData?.imageName,
    };
    req()
      .put(`corporations/`, { ...updatedUser, ...imageInfo })
      .then(({ data: user }) => {
        setUpdatedUser(user);
        props.updateUser(user);
        props.addToast({
          message: "Billedet er blevet skiftet!",
          type: "success",
          autoDismiss: true,
        });
        setIconSpinner(false);
      });
  };

  const uploadProfilePicture = (file) => {
    const imageInfo = {
      baseURL_image,
      profile_image: file?.imageData?.imageName,
    };
    req()
      .put(`corporations/`, { ...updatedUser, ...imageInfo })
      .then(({ data: user }) => {
        setUpdatedUser(user);
        props.updateUser(user);
        props.addToast({
          message: "Billedet er blevet skiftet!",
          type: "success",
          autoDismiss: true,
        });
        setProfileSpinner(false);
      });
  };

  const changePassword = () => {
    const validation = validator.validate(password);
    const tempPassword = { ...password };
    tempPassword.validation = validation;
    setSubmitted(true);
    if (validation.isValid) {
      req()
        .put(`corporations/change-password`, { password: password.password })
        .then(() => {
          props.addToast({
            message: "Kodeordet er blevet opdateret",
            type: "success",
            autoDismiss: true,
          });
          setPassword({
            ...password,
            confirmPassword: "",
            password: "",
          });
        })
        .catch(() => {
          props.addToast({
            message: "Noget gik galt, da vi prøvede at opdatere dit kodeord",
            type: "error",
            autoDismiss: true,
          });
        });
    } else {
      setPassword(tempPassword);
      props.addToast({
        message: "Kodeord er ikke korrekt udfyldt",
        type: "warning",
        autoDismiss: true,
      });
    }

    setPasswordButton(false);
  };

  return (
    <div className={componentStyle(colors)}>
      {loading && <Spinner title={"Indlæser ændringerne..."} />}

      {!loading && (
        <>
          <p className="text-header">Min Virksomhed</p>
          <p className="sub-header">Her kan du ændre dine oplysninger</p>
          <div className="section">
            <p className="title">LOGO</p>
            {!iconSpinner && (
              <ImageUpload
                spinner={setIconSpinner}
                onFile={(file) => {
                  uploadLogo(file);
                }}
                type={"corporationProfile"}
              >
                <div
                  className={
                    updatedUser.icon_image ? "uploaded-image" : "no-image"
                  }
                >
                  {updatedUser.icon_image && (
                    <img
                      src={`${updatedUser.baseURL_image}h_100,w_100/dpr_2.0/r_max/${updatedUser.icon_image}`}
                      alt={"icon"}
                    />
                  )}
                  {!updatedUser.icon_image && <p>Upload logo</p>}
                </div>
              </ImageUpload>
            )}
            {iconSpinner && <Spinner title={"Opdater billede..."} />}
          </div>

          <div className="section-one">
            <div className="corporation-name-container">
              <p>Virksomhedens navn</p>
              <input
                name="name"
                className="default-input"
                value={updatedUser.name}
                placeholder={updatedUser.name}
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
            <div className="cvr-container">
              <p>CVR nr.</p>
              <input
                name="cvr_nr"
                className="default-input"
                value={updatedUser.cvr_nr}
                placeholder={updatedUser.cvr_nr}
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="section-two">
            <div className="address-container">
              <p>Adresse</p>
              <input
                className="default-input"
                value={updatedUser.address}
                name="address"
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
            <div className="zip-container">
              <p>Post nr.</p>
              <input
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
                className="default-input"
                value={updatedUser.city_zip}
                name="city_zip"
              />
            </div>
            <div className="city-container">
              <p>By</p>
              <input
                value={updatedUser.city}
                name="city"
                className="default-input"
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <hr />

          <div className="section">
            <p className="title">PROFILBILLEDE</p>
            {!profileSpinner && (
              <ImageUpload
                spinner={setProfileSpinner}
                onFile={(file) => {
                  uploadProfilePicture(file);
                }}
                type={"corporationProfile"}
              >
                <div
                  className={
                    updatedUser.profile_image ? "uploaded-image" : "no-image"
                  }
                >
                  {updatedUser.profile_image && (
                    <img
                      src={`${updatedUser.baseURL_image}h_100,w_100/dpr_2.0/r_max/${updatedUser.profile_image}`}
                      alt={"profile_image"}
                    />
                  )}
                  {!updatedUser.profile_image && <p>Upload profilbillede</p>}
                </div>
              </ImageUpload>
            )}

            {profileSpinner && <Spinner title={"Opdater billede..."} />}
          </div>

          <div className="section-three">
            <div className="container-one">
              <p>Kontakt person</p>
              <input
                value={updatedUser.contact_person}
                name="contact_person"
                className="default-input"
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="container-two">
              <p>Afdeling</p>
              <input
                value={updatedUser.department || ''}
                name="department"
                className="default-input"
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="section-three">
            <div className="container-one">
              <p>Email / Brugernavn</p>
              <input
                value={updatedUser.email}
                placeholder={updatedUser.email}
                name="email"
                onChange={(e) => {
                  setBillingEmailNotValid(validateEmail(e.target.value));

                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
                className={`default-input ${!billingEmailNotValid ? "not-valid" : ""
                  }`}
              />
            </div>

            <div className="container-two">
              <p>Mobil nr.</p>
              <input
                value={updatedUser.phone}
                name="phone"
                className="default-input"
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className="section-three">
            <div className="container-one">
              <p>Fakturerings email </p>
              <input
                value={updatedUser.billing_email}
                name="billing_email"
                className="default-input"
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>

            <div className="container-two">
              <p>EAN</p>
              <input
                value={updatedUser.ean || ''}
                name="ean"
                className="default-input"
                onChange={(e) => {
                  setUpdatedUser({
                    ...updatedUser,
                    [e.target.name]: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <>
            <div className="section-radio">
              <p className="radio-label">
                Betalingtype
              </p>
              <div className="radio-container">
              <div className="radio-div">
                  <input
                    checked={updatedUser.payment_type === "invoice"}
                    type="radio"
                    className="radio"
                    name="payment_type"
                    value={"invoice"}
                    disabled={!(user.is_admin || takenOverCorporation)}
                    onChange={(e) => {
                      setUpdatedUser({
                        ...updatedUser,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <span>Faktura</span>
                </div>
                <div className="radio-div">
                  <input
                    checked={updatedUser.payment_type === "credit_card"}
                    type="radio"
                    className="radio"
                    name="payment_type"
                    value={"credit_card"}
                    disabled={!(user.is_admin || takenOverCorporation)}
                    onChange={(e) => {
                      setUpdatedUser({
                        ...updatedUser,
                        [e.target.name]: e.target.value,
                      });
                    }}
                  />
                  <span>Kreditkort <br />
                    (kommer snart)</span>
                </div>      
              </div>
            </div>
            <div className="section-radio">
            <p className="radio-label">
              Betalingsfrist
            </p>
            <div className="radio-container">
              <div className="radio-div">
                <input
                  checked={parseFloat(updatedUser.payment_deadline) === 0}
                  type="radio"
                  className="radio"
                  name="payment_deadline"
                  value={0}
                  disabled={!(user.is_admin || takenOverCorporation)}
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <span>0 dage <br />
                  Hyret personle</span>
              </div>

              <div className="radio-div">
                <input
                  checked={parseFloat(updatedUser.payment_deadline) === 7}
                  type="radio"
                  className="radio"
                  name="payment_deadline"
                  value={7}
                  disabled={!(user.is_admin || takenOverCorporation)}
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <span>7 dage <br />
                  (kreditgodkendt)</span>
              </div>

              <div className="radio-div">
                <input
                  checked={parseFloat(updatedUser.payment_deadline) === 15}
                  type="radio"
                  className="radio"
                  name="payment_deadline"
                  value={15}
                  disabled={!(user.is_admin || takenOverCorporation)}
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <span>15 dage <br />
                  (Farvoitkunde)</span>
              </div>

              <div className="radio-div">
                <input
                  checked={parseFloat(updatedUser.payment_deadline) === 30}
                  type="radio"
                  className="radio"
                  name="payment_deadline"
                  value={30}
                  disabled={!(user.is_admin || takenOverCorporation)}
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: e.target.value,
                    });
                  }}
                />
                <span className="line-through">30 dage</span>
              </div>
            </div>
            </div>
          </>
          <p className="text-header">Adgangskoder</p>
          <p className="sub-header">
            Udfyld begge felter for at skifte kodeordet
          </p>
          {!showChangePassword && (
            <button
              className="change-password-btn"
              onClick={() => setShowChangePassword(true)}
            >
              Skift adgangskode
            </button>
          )}

          <p className="text-header" style={{ marginTop: '20px' }}>
            Notifikationer
          </p>
          <table>
            <thead>
              <tr>
                <td>E-mail</td>
                <td>SMS</td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <input type="checkbox"
                    name="noti_confirmed_booking"
                    checked={updatedUser.noti_confirmed_booking}
                    value={updatedUser.noti_confirmed_booking}
                    onClick={(e) => {
                      const checkVal = e.target.value == "true";
                      console.log({ name: e.target.name, val: e.target.value, valc: !checkVal })
                      setUpdatedUser({
                        ...updatedUser,
                        [e.target.name]: !checkVal
                      })
                    }}
                  /></td>
                <td></td>
                <td className="noti-title">Bekræftet booking</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_approved_booking"
                  checked={updatedUser.noti_approved_booking}
                  value={updatedUser.noti_approved_booking}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }} /></td>
                <td></td>
                <td className="noti-title">Godkendt booking</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_stamp_out_booking"
                  checked={updatedUser.noti_stamp_out_booking}
                  value={updatedUser.noti_stamp_out_booking}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }} /></td>
                <td><input type="checkbox" name="sms_stamp_out_booking"
                  checked={updatedUser.sms_stamp_out_booking}
                  value={updatedUser.sms_stamp_out_booking}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }} /></td>
                <td className="noti-title">Afslut booking/stempel ud</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_cancel_booking"
                  checked={updatedUser.noti_cancel_booking}
                  value={updatedUser.noti_cancel_booking}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }} /></td>
                <td></td>
                <td className="noti-title">Annulleret booking</td>
              </tr>
              <tr>
                <td><input type="checkbox" name="noti_update_booking"
                  checked={updatedUser.noti_update_booking}
                  value={updatedUser.noti_update_booking}
                  onClick={(e) => {
                    const checkVal = e.target.value == "true";
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !checkVal
                    })
                  }} /></td>
                <td></td>
                <td className="noti-title">Opdater tid / adresse</td>
              </tr>
            </tbody>
          </table>
          {showChangePassword && (
            <div className="section-three">
              <div className="container-one">
                <p>Indtast nyt kodeord</p>
                <input
                  type="password"
                  name="password"
                  value={password.password}
                  className="default-input"
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                <p className="password-validation">
                  {validation.password.message}
                </p>
              </div>
              <div className="container-two">
                <p>Gentag kodeord</p>
                <input
                  type="password"
                  name="confirmPassword"
                  className="default-input"
                  value={password.confirmPassword}
                  onChange={(e) =>
                    setPassword({
                      ...password,
                      [e.target.name]: e.target.value,
                    })
                  }
                />
                <p className="password-validation">
                  {validation.confirmPassword.message}
                </p>
              </div>
            </div>
          )}

          {button && (
            <div className="save-changes-container">
              <button onClick={() => update()}>Gem mine ændringer</button>
            </div>
          )}

          {passwordButton && (
            <div className="change-password-btn-container">
              <button onClick={() => changePassword()}>
                Skift adgangskode
              </button>
            </div>
          )}

          {(user.is_admin || takenOverCorporation) && (
            <div className="admin-section">
              <div className="approve-booking">
                <p>Admin skal godkende alle bookinger</p>
                <input
                  type="checkbox"
                  name="is_privileged_user"
                  defaultChecked={!(updatedUser.is_privileged_user)}
                  onClick={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: !updatedUser.is_privileged_user,
                    });
                  }}
                />
              </div>
              <div className="admin-message">
                <p>Admin besked</p>
                <textarea
                  name="admin_message"
                  value={updatedUser.admin_message}
                  onChange={(e) => {
                    setUpdatedUser({
                      ...updatedUser,
                      [e.target.name]: e.target.value,
                    });
                  }}
                  rows="5"
                />
              </div>
              <div className="section-four">
                <div className="price-container">
                  <label>Service Tillæg</label>
                  <div style={{ position: "relative" }}>
                    <input
                      value={updatedUser.standard_fee}
                      name="standard_fee"
                      className="default-input"
                      onChange={(e) => {
                        if (!(/^[0-9,]+$/.test(e.target.value))) return;
                        setUpdatedUser({
                          ...updatedUser,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <div className="png-div-left">
                      <p className="text-sm">DKK</p>
                    </div>
                  </div>
                  <p className="invalid-text">{validation.hourly_wage?.message || validation.fee?.message}</p>
                </div>
                <div className="price-container">
                  <label>{`Hastebooking (<48 timer)`}</label>
                  <div style={{ position: "relative" }}>
                    <input
                      value={updatedUser.urgent_booking_fee}
                      name="urgent_booking_fee"
                      className="default-input"
                      onChange={(e) => {
                        if (!(/^[0-9,]+$/.test(e.target.value))) return;
                        setUpdatedUser({
                          ...updatedUser,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <div className="png-div-left">
                      <p className="text-sm">DKK</p>
                    </div>
                  </div>
                  <p className="invalid-text">{validation.hourly_wage?.message || validation.fee?.message}</p>
                </div>
                <div className="price-container">
                  <label>{`Akutbooking (<24 timer)`}</label>
                  <div style={{ position: "relative" }}>
                    <input
                      value={updatedUser.emergency_booking_fee}
                      name="emergency_booking_fee"
                      className="default-input"
                      onChange={(e) => {
                        if (!(/^[0-9,]+$/.test(e.target.value))) return;
                        setUpdatedUser({
                          ...updatedUser,
                          [e.target.name]: e.target.value,
                        });
                      }}
                    />
                    <div className="png-div-left">
                      <p className="text-sm">DKK</p>
                    </div>
                  </div>
                  <p className="invalid-text">{validation.hourly_wage?.message || validation.fee?.message}</p>
                </div>

                <div className="worker-option">
                  <p>{`>9t+50%`}</p>
                  <Switch
                    className="worker-switch"
                    onChange={() => {
                      setUpdatedUser({
                        ...updatedUser,
                        extra_fee: !updatedUser.extra_fee,
                      });
                    }}
                    checked={updatedUser.extra_fee}
                    uncheckedIcon={false}
                    checkedIcon={false}
                  />
                </div>

              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

const componentStyle = (colors) => css`
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
  padding-right: 3rem;
  padding-bottom: 4rem;
  table {
    max-width: 60%;
    td.noti-title {
      text-align: left;
      padding-top: 0;
    }

    td{
      text-align: center;
      font-size: 10px;
      padding: 5px 0;
    }
  }

  .radio-container {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    .radio-div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      -webkit-align-items: center;
      -webkit-justify-content: center;

      input {
        margin-bottom: 0.5rem;
        width: 20px;
        height: 20px;
        accent-color: darkgray;
      }

      span {
        margin-left: 0.5rem;
        font-size: 0.65rem;
        padding-right: 0.5rem;
        text-align: center;
      }
      .line-through {
        text-decoration: line-through;
      }
    }
  }

  .text-header {
    font-size: 1.5rem;
  }

  .sub-header {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
    color: ${colors.black};
  }

  .section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 1.25rem;

    img {
      object-fit: cover;
      height: 100%;
      width: 100%;
    }

    .title {
      font-weight: 800;
      text-transform: uppercase;
      margin-bottom: 0.5rem;
    }

    .uploaded-image {
      border: 1px solid ${colors.darkGrey};
      border-radius: 9999px;
      height: 9.2rem;
      width: 9.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
    }

    .no-image {
      text-align: center;
      border: 1px solid ${colors.darkGrey};
      border-radius: 9999px;
      height: 9.2rem;
      width: 9.2rem;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      cursor: pointer;
      font-size: 1rem;
    }

    .no-image:hover {
      background-color: ${colors.darkerGrey};
      color: ${colors.white};
      cursor: pointer;
    }
  }

  .section-one {
    display: flex;
    margin-bottom: 1.25rem;

    .corporation-name-container {
      display: flex;
      flex-direction: column;
      width: 66.666667%;
      margin-right: 0.5rem;
    }

    .cvr-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    p {
      font-size: 1rem;
      padding-left: 0.5rem;
    }
  }

  .section-two {
    display: flex;
    margin-bottom: 1.25rem;

    .address-container {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-right: 0.5rem;
    }

    .zip-container {
      display: flex;
      flex-direction: column;
      width: 6.5rem;
      margin-right: 0.5rem;
    }

    .city-container {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    p {
      font-size: 1rem;
      padding-left: 0.5rem;
    }
  }
  .section-radio {
    margin-bottom: 1.25rem;
  }
  .section-three {
    display: flex;
    margin-bottom: 1.25rem;

    .container-one {
      display: flex;
      flex-direction: column;
      width: 50%;
      margin-right: 0.5rem;
    }

    .container-two {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
  }
  .section-four {
    display: flex;
    margin: 1.25rem 0;
    flex-direction: column;
    .price-container {
      width: 50%;
      margin-right: 0.5rem;
    }
    .png-div-left,
    .png-div-right {
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      display: flex;
      align-items: center;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      color: ${colors.black};
    }
  
  }
  .marked-input {
    display: block;
    font-size: 0.75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    background-color: ${colors.grey};
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    outline: none;
  }

  .default-input {
    display: block;
    font-size: 0.75rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    border: 1px solid ${colors.darkGrey};
    padding: 0.5rem;
    border-radius: 0.25rem;
    line-height: 1.25;
    outline: none;
  }

  .not-valid {
    border: 2px solid ${colors.red};
  }

  .password-validation {
    padding: 0.25rem;
    color: ${colors.red};
    font-weight: 600;
    font-size: 0.85rem;
  }

  .change-password-btn {
    width: 19.2rem;
    background-color: ${colors.darkRed};
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    color: ${colors.white};
    font-weight: 700;
    outline: none;
  }

  .change-password-btn:hover {
    background-color: ${colors.red};
  }

  .change-password-btn-container,
  .save-changes-container {
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    button {
      background-color: ${colors.darkRed};
      width: 36rem;
      margin-left: 3rem;
      padding: 0.75rem 1.25rem;
      border-radius: 0.25rem;
      font-weight: 700;
      color: ${colors.white};
      border: 1px solid ${colors.darkGrey};
    }

    button:hover {
      background-color: ${colors.red};
    }

    button:focus {
      outline: none;
    }
  }

  .save-changes-container {
    button {
      background-color: ${colors.mediumBlue};
    }

    button:hover {
      background-color: ${colors.blue};
    }
  }

  .marked-input::placeholder {
    color: ${colors.black};
  }

  .default-input::placeholder {
    color: ${colors.black};
  }

  hr {
    border: 1px solid ${colors.grey};
    border-top-width: 0;
    margin-bottom: 1.25rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }

  .admin-section {
    display: flex;
    flex-direction: column;


    .approve-booking {
      display: flex;
      margin-top: 40px;
      margin-left: 5px;
      margin-right: 5px;
  
      p {
        font-weight: 500;
        margin-right: 5px;
      }
    }

    .admin-message {
      display: flex;
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
      flex-direction: column;

      textarea {
        border: 1px solid #707070;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        line-height: 1.25;
        font-size: 0.75rem;
        cursor: text;
      }
    }
  }

`;

const mapDispatchToProps = (dispatch) => ({
  updateUser: bindActionCreators(updateUser, dispatch),
  addToast: bindActionCreators(addToast, dispatch),
});

export default connect(null, mapDispatchToProps)(Account);
